import React, { useEffect } from 'react';
import { Box, Typography, Divider } from '@mui/material';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles

function MissionVisionSection() {
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialize AOS with a 1-second duration
  }, []);

  return (
    <Box 
      sx={{
        backgroundColor: '#f6f0e6', // Light beige background
        padding: '60px 20px',
      }}
    >
      {/* Mission Section */}
      <div data-aos="fade-up">
        <Typography 
          variant="h4" 
          gutterBottom
          sx={{
            textAlign: 'center',
            fontWeight: 'bold',
            fontFamily: '"Space Grotesk", sans-serif',
            color: '#8E7C57', // Golden tan color
          }}
        >
          Our Mission
        </Typography>

        <Typography
          variant="body1"
          sx={{
            maxWidth: '800px',
            margin: '0 auto',
            fontSize: '18px',
            textAlign: 'center',
            color: '#6D5D4B', // Subtle dark brown for text
            fontFamily: '"Space Grotesk", sans-serif',
          }}
        >
          We aim to expand our footprint to overseas markets by enhancing our production capabilities and increasing our workforce. 
          At the same time, we are committed to setting new benchmarks in quality, making our mark in the interior design industry across Kerala and India.
        </Typography>
      </div>

      {/* Divider */}
      <Divider sx={{ margin: '40px auto', width: '50%', backgroundColor: '#a89160' }} />

      {/* Vision Section */}
      <div data-aos="fade-up">
        <Typography 
          variant="h4" 
          gutterBottom
          sx={{
            textAlign: 'center',
            fontWeight: 'bold',
            fontFamily: '"Space Grotesk", sans-serif',
            color: '#8E7C57', // Golden tan color
          }}
        >
          Our Vision
        </Typography>

        <Typography
          variant="body1"
          sx={{
            maxWidth: '800px',
            margin: '0 auto',
            fontSize: '18px',
            textAlign: 'center',
            color: '#6D5D4B', // Subtle dark brown for text
            fontFamily: '"Space Grotesk", sans-serif',
          }}
        >
          Our vision is to be recognized as the go-to name for contemporary and accessible interior design solutions, becoming a trusted partner for home transformations throughout Kerala and India.
        </Typography>
      </div>

      {/* Conclusion Section */}
      <Box sx={{ textAlign: 'center', marginTop: '60px' }}>
        <Typography 
          variant="h5"
          sx={{
            fontFamily: '"Space Grotesk", sans-serif',
            fontWeight: 'bold',
            color: '#8E7C57',
          }}
        >
          Let's Build Your Dream Space Together!
        </Typography>
      </Box>
    </Box>
  );
}

export default MissionVisionSection;
