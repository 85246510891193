import React, { useState } from 'react';
import { Box, Grid, Typography, Card, CardMedia, Dialog } from '@mui/material';
import PlayCircleIcon from '@mui/icons-material/PlayCircle'; // Icon for play button overlay
import { motion } from 'framer-motion';

const galleries = {
  imageGallery: [
    'https://i.postimg.cc/59Y8j4FD/3d-rendering-scandinavian-vintage-modern-kitchen-with-dining-area.jpg',
    'https://i.postimg.cc/5ySvn9vf/modern-bathroom-with-big-mirror.jpg',
    'https://i.postimg.cc/6TxZxZWn/3d-rendering-loft-luxury-living-room-with-bookshelf.jpg',
    'https://i.postimg.cc/cCxGtTBX/3d-rendering-beautiful-comtemporary-luxury-bedroom-suite-hotel-with-tv.jpg',
    
  ],
  videoGallery: [
    'https://www.youtube.com/watch?v=OzUkvzyBttA',
    'https://www.youtube.com/watch?v=ej2fZkqXi6I',
    'https://www.youtube.com/watch?v=cRiVW5WFkxY',
    'https://www.youtube.com/watch?v=uaoVf_sjfrI',
  ],
};

function GallerySection() {
  const [open, setOpen] = useState(false); // Modal open state
  const [selectedMedia, setSelectedMedia] = useState(null); // Selected media (image or video)
  const [isVideo, setIsVideo] = useState(false); // Flag to check if media is video

  const handleClickOpen = (media, isVideoType) => {
    setSelectedMedia(media);
    setIsVideo(isVideoType);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedMedia(null);
  };

  const getEmbedUrl = (url) => {
    const videoId = url.split('v=')[1];
    return `https://www.youtube.com/embed/${videoId}`;
  };

  const getVideoThumbnail = (url) => {
    const videoId = url.split('v=')[1];
    return `https://img.youtube.com/vi/${videoId}/0.jpg`; // Fetches the default thumbnail image
  };

  return (
    <Box
      sx={{
        backgroundColor: '#e8d8b8',
        padding: { xs: '20px 10px', sm: '40px 20px' },
      }}
    >
      <Grid container spacing={4} justifyContent="center">
        {/* Image Gallery */}
        <Grid item xs={12} md={5}>
          <Card sx={{ boxShadow: 'none', backgroundColor: 'transparent' }}>
            <Grid container spacing={2}>
              {galleries.imageGallery.map((image, index) => (
                <Grid item xs={6} key={index}>
                  <motion.div
                    whileHover={{ scale: 1.05 }}
                    transition={{ type: 'spring', stiffness: 300 }}
                  >
                    <CardMedia
                      component="img"
                      image={image}
                      alt={`Image ${index}`}
                      sx={{
                        width: '100%',
                        height: { xs: '100px', md: '150px' },
                        objectFit: 'cover',
                        cursor: 'pointer',
                        borderRadius: '8px', // Rounded corners
                      }}
                      onClick={() => handleClickOpen(image, false)}
                    />
                  </motion.div>
                </Grid>
              ))}
            </Grid>
            <Typography
              variant="h6"
              align="center"
              sx={{
                fontWeight: 'bold',
                color: '#9e8856',
                marginTop: '10px',
                backgroundColor: '#fff',
                padding: '10px 0',
              }}
            >
              Image Gallery
            </Typography>
          </Card>
        </Grid>

        {/* Video Gallery */}
        <Grid item xs={12} md={5}>
          <Card sx={{ boxShadow: 'none', backgroundColor: 'transparent' }}>
            <Grid container spacing={2}>
              {galleries.videoGallery.map((video, index) => (
                <Grid item xs={6} key={index} sx={{ position: 'relative' }}>
                  <motion.div
                    whileHover={{ scale: 1.05 }}
                    transition={{ type: 'spring', stiffness: 300 }}
                  >
                    <CardMedia
                      component="img"
                      image={getVideoThumbnail(video)}
                      alt={`Video ${index}`}
                      sx={{
                        width: '100%',
                        height: { xs: '100px', md: '150px' },
                        objectFit: 'cover',
                        cursor: 'pointer',
                        borderRadius: '8px', // Rounded corners
                      }}
                      onClick={() => handleClickOpen(video, true)}
                    />
                    {/* Play Icon Overlay */}
                    <PlayCircleIcon
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        fontSize: '48px',
                        color: 'rgba(255, 255, 255, 0.8)',
                      }}
                    />
                  </motion.div>
                </Grid>
              ))}
            </Grid>
            <Typography
              variant="h6"
              align="center"
              sx={{
                fontWeight: 'bold',
                color: '#9e8856',
                marginTop: '10px',
                backgroundColor: '#fff',
                padding: '10px 0',
              }}
            >
              Video Gallery
            </Typography>
          </Card>
        </Grid>
      </Grid>

      {/* Modal for Viewing Image or Playing Video */}
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        {isVideo ? (
          selectedMedia ? (
            <iframe
              width="100%"
              height="400px"
              style={{
                maxWidth: '100%',
              }}
              src={getEmbedUrl(selectedMedia)}
              title="YouTube Video"
              frameBorder="0"
              allowFullScreen
            />
          ) : null
        ) : (
          <img
            src={selectedMedia}
            alt="Selected"
            style={{
              width: '100%',
              height: 'auto',
              maxWidth: '100%',
              margin: '0 auto',
            }}
          />
        )}
      </Dialog>
    </Box>
  );
}

export default GallerySection;
